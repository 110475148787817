<template>
    <div>
        <div v-if="authUserPermission['mcr-draft-list-view']">
            <base-header class="pb-6">
                <div class="row align-items-center py-4">
                    <div class="col-lg-6 col-7">
                        <h6 class="h2 text-white d-inline-block mb-0"></h6>
                    </div>      
                </div>
            </base-header>
            <div class="container-fluid mt--6">
                <div class="card mb-4">
                    <div class="card-header mt--4">
                        <div class="row">
                            <div class="col">
                                <h3 class="mb-0">{{ tt('draft_list') }}</h3>
                            </div>
                            <div class="text-right mr-3">
                                <base-button size="sm" type="default" @click="filter">{{ tt('filter') }}</base-button>
                                <base-button size="sm" type="default" @click="create">{{ tt('add_new') }}</base-button>
                            </div>
                        </div>
                    </div>
                    <div>
                        <el-table height="350px" class="table-responsive table-flush" header-row-class-name="thead-light" :data="table.data" v-if="!onLoad">
                            <el-table-column :label="tt('type')" :prop="tt('type')" min-width="152px" sortable>
                                <template v-slot="{row}">
                                    {{row.form_description}}
                                </template>
                            </el-table-column>
                            <el-table-column :label="tt('subject')" :prop="tt('subject')" min-width="150px" sortable>
                                <template v-slot="{row}">
                                    {{row.subject}}
                                </template>
                            </el-table-column>
                            <el-table-column :label="tt('catalog_type')" :prop="tt('catalog_type')" min-width="164px" sortable>
                                <template v-slot="{row}">
                                    {{row.catalog_type_code}}
                                </template>
                            </el-table-column>
                            <el-table-column :label="tt('total_item')" :prop="tt('total_item')" min-width="152px" sortable>
                                <template v-slot="{row}">
                                    {{row.count_item_count}}
                                </template>
                            </el-table-column>
                            <el-table-column :label="tt('created_at')" :prop="tt('created_at')" min-width="171px" sortable>
                                <template v-slot="{row}">
                                    {{format_date(row.created_at)}}
                                </template>
                            </el-table-column>
                            <el-table-column prop="action" width="100">
                                <template v-slot="{row}">
                                    <el-dropdown trigger="click" class="dropdown" @command="handleTableAction" :hide-on-click="true">
                                        <span class="btn btn-sm btn-icon-only text-light">
                                            <i class="fas fa-ellipsis-v mt-2"></i>
                                        </span>
                                        <el-dropdown-menu class="dropdown-menu dropdown-menu-arrow show" slot="dropdown">
                                            <router-link :to="'/material/mcr-form/'+row.mcr_code+'/'+row.token+'/edit'" class="dropdown-item ml-1" tag="button">{{ tt('edit') }}</router-link>
                                            <el-dropdown-item :command="{action:'remove', data:row}">{{ tt('delete') }}</el-dropdown-item>                                        
                                        </el-dropdown-menu>
                                    </el-dropdown>
                                </template>
                            </el-table-column>
                        </el-table>
                        <page-loading v-else/>
                    </div>  
                    <div class="card-footer pb-0 ">   
                        <span class="float-left">
                            <base-pagination :page-count="table.page.last_page" v-model="table.page.current_page" @click.native="changePage(table.page.current_page)" size="sm"></base-pagination>
                        </span>                             
                        <span class="float-right">
                            {{ tt('page_info', {size: (table.page.current_page - 1) * table.page.per_page + table.data.length, total: table.total}) }}
                        </span>
                    </div>
                </div>
            </div>
            <validation-observer v-slot="{invalid}">
                <modal :show.sync="form.show">
                    <template slot="header">
                        <h5 class="modal-title">{{form.title}}</h5>
                    </template>
                    <div>
                        <label class="form-control-label">{{ tt('subject') }} <span class="text-danger">*</span></label>
                        <base-input :name="tt('subject')" :placeholder="tt('mcr_subject')" v-model="draftList.subject" rules="required"></base-input>

                        <label class="form-control-label">{{ tt('type') }} <span class="text-danger">*</span></label>
                        <base-input :name="tt('type')" rules="required">
                            <el-select class="select-danger" v-model="draftList.type" :placeholder="tt('choose_type')">
                                <el-option class="select-danger" :value="mf['form_code']" :label="mf['form_code'] + ' - ' + mf['form_description']" :key="mf['form_code'] + ' - ' + mf['form_description']" v-for="mf in  masterForm"></el-option>
                            </el-select>
                        </base-input>

                        <label class="form-control-label">{{ tt('catalog_type') }} <span class="text-danger">*</span></label>
                        <base-input :name="tt('catalog_type')" rules="required">
                            <el-select class="select-danger" v-model="draftList.catalog_type_code" :placeholder="tt('choose_catalog_type')">
                                <el-option class="select-danger" :value="ct['code']" :label="ct['code'] + ' - ' + ct['description']" :key="ct['code'] + ' - ' + ct['description']" v-for="ct in  catalogType"></el-option>
                            </el-select>
                        </base-input>
                    </div>
                    <template slot="footer">
                        <base-button type="secondary" @click="form.show = false">{{ tt('close') }}</base-button>
                        <base-button type="primary" v-on:click="save" :disabled="btnSave.onLoading || invalid">
                            <span v-if="btnSave.onLoading"><i class="fas fa-spinner fa-spin"></i> {{ tt('please_wait') }}</span>
                            <span v-else>
                                {{ tt('add') }}
                            </span>
                        </base-button>
                    </template>
                </modal>
            </validation-observer>
            <modal :show.sync="formFilter.show">
                <template slot="header">
                    <h5 class="modal-title">{{ tt('filter') }}</h5>
                </template>
                <div>
                    <label class="form-control-label">{{ tt('type') }}</label>
                    <base-input :name="tt('type')">
                        <el-select class="select-danger" v-model="table.search.type" :placeholder="tt('choose_type')">
                            <el-option class="select-danger" :value="mf['form_code']" :label="mf['form_code'] + ' - ' + mf['form_description']" :key="mf['form_code'] + ' - ' + mf['form_description']" v-for="mf in  masterForm"></el-option>
                        </el-select>
                    </base-input>
                    
                    <label class="form-control-label">{{ tt('catalog_type') }}</label>
                    <base-input :name="tt('catalog_type')">
                        <el-select class="select-danger" v-model="table.search.catalog_type" :placeholder="tt('choose_catalog_type')">
                            <el-option class="select-danger" :value="ct['code']" :label="ct['code'] + ' - ' + ct['description']" :key="ct['code'] + ' - ' + ct['description']" v-for="ct in  catalogType"></el-option>
                        </el-select>
                    </base-input>

                    <label class="form-control-label">{{ tt('subject') }}</label>
                    <base-input :name="tt('subject')" :placeholder="tt('subject')" v-model="table.search.subject"></base-input>

                    <label class="form-control-label">{{ tt('created_at') }}</label>
                    <base-input type="date" :name="tt('created_at')" :placeholder="tt('created_at')" v-model="table.search.created_at"/>
                </div>
                <template slot="footer">
                    <base-button type="primary" v-on:click="filtering" :disabled="btnFilter.onLoading">
                        <span v-if="btnFilter.onLoading"><i class="fas fa-spinner fa-spin"></i> {{ tt('please_wait') }}</span>
                        <span v-else>
                            {{ tt('filter') }}
                        </span>
                    </base-button>
                </template>
            </modal>
        </div>
        <noaccess v-else/>
    </div>
</template>
<script>
    import {mapState} from 'vuex'
    import Api from '@/helpers/api';
    import draftList from '@/services/material/draftList.service';
    import catalogType from '@/services/master/catalogType.service';
    import form from '@/services/setting/form.service';
    var moment = require('moment');
    
    export default {        
        data() {
            return { 
                moment:moment,                   
                onLoad: true,      
                loadTimeout: null,  
                btnSave: {
                    onLoading: false
                },  
                btnFilter: {
                    onLoading: false
                },
                form: {
                    title: this.tt('add_new_mcr'),
                    show: false
                },
                formFilter: {
                    show: false
                },   
                table: {
                    search: {
                        type: '',
                        catalog_type: '',
                        subject: '',
                        created_at: ''
                    },                    
                    total: 0,
                    data: [],
                    page: [],
                },     
                errorMessage: null,   
                draftList: {},
                catalogType: {},
                masterForm: {}  
            }
        },
        computed: {
            ...mapState({
                authUserPermission :state => state.auth.userPermission
            }),
        },
        mounted() {
            this.get()
            this.getCatalogType()
            this.getForm()
        },
        methods: {
            get() { 
                let context = this;               
                Api(context, draftList.getDraftList(this.table.page.current_page, {search: this.table.search})).onSuccess(function(response) {    
                    context.table.total = response.data.data.data.total;
                    context.table.data = response.data.data.data.data; 
                    context.table.page  = response.data.data.data; 
                    context.onLoad = false;          
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.table.data = [];
                        context.table.total = 0;
                        context.onLoad = false;
                    }
                })
                .call()
            },
            getCatalogType() {
                let context = this;               
                Api(context, catalogType.getPurchaseByCompany()).onSuccess(function(response) {
                    context.catalogType = response.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.catalogType = [];
                    }
                })
                .call()
            },
            getForm() {
                let context = this;               
                Api(context, form.getByRole()).onSuccess(function(response) {    
                    context.masterForm = response.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.masterForm = [];
                    }
                })
                .call()
            },
            filter() {
                this.formFilter.show = true
            },
            filtering() {
                let context = this;
                context.onLoad = true;
                context.btnFilter.onLoading = true;
                Api(context, draftList.getDraftList('none', {type: context.table.search.type, catalog_type: context.table.search.catalog_type, subject: context.table.search.subject, created_at: context.table.search.created_at})).onSuccess(function(response) {    
                    context.table.data = response.data.data.data.data;
                    context.table.total = response.data.data.data.total;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.table.data = [];
                    }
                }).onFinish(function() {
                    context.onLoad = false;
                    context.btnFilter.onLoading = false;
                    context.formFilter.show = false;
                }).call()
            },
            handleTableAction(command) {
                switch (command.action) {
                    case 'edit':
                            this.edit(command.data);
                        break;
                    case 'remove':
                            this.remove(command.data);
                        break;
                    default:
                        break;
                }
            },
            create() {
                this.errorMessage = null;
                this.form.add = true;
                this.form.title = this.tt('add_new_mcr');
                this.form.show = true;
                this.draftList = {};
            },            
            edit(id) {
                this.errorMessage = null;
                let context = this;               
                Api(context, draftList.show(id)).onSuccess(function(response) {
                    context.draftList = response.data.data;
                    context.form.add = false;
                    context.form.title = context.tt('edit_new_mcr');
                    context.form.show = true;                          
                })
                .call()        
            },
            save() {                      
                this.btnSave.onLoading = true;
                let api = null;
                let context = this;

                if (this.form.add) {
                    api = Api(context, draftList.create(this.draftList));
                } else {{
                    api = Api(context, draftList.update(this.draftList.id, this.draftList));
                }}
                api.onSuccess(function(response) {
                    context.get();
                    context.form.show = false;

                    clearTimeout(this.loadTimeout);
                    this.loadTimeout = setTimeout(() => {
                        context.$router.push({ path: '/material/mcr-form/'+response.data.data.mcr_code+'/'+response.data.data.token+'/edit' });
                    }, 100);
                }).onError(function(error) {                    
                    context.errorMessage = error.response.data;
                }).onFinish(function() {
                    context.btnSave.onLoading = false;   
                })
                .call();
            },
            remove(params) {
                let context = this;
                context.confirmDialog(context.tt('confirm_delete')).then((result) => {
                    if (result.value) {
                        context.onLoad = true;
                        Api(context, draftList.deleteMcr(params.mcr_code, params.token)).onSuccess(function(response) {
                            context.$notify({
                                message: response.data.message,
                                type: 'success',
                                verticalAlign: 'bottom', 
                                horizontalAlign: 'left'
                            });
                            context.get();
                            context.onLoad = false;
                        }).call();
                    }
                })
            },
            changePage(page) {
                let context = this;
                context.onLoad = true;

                clearTimeout(this.loadTimeout);
                this.loadTimeout = setTimeout(() => {
                    this.get()
                }, 10);
            },
            format_date(value){
                if (value) {
                   return moment(String(value)).locale('id').format('LLL')
                }
            },
        }   
    };
</script>
<style></style>
